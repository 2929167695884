import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../containers/Home';
import UsersReports from '../containers/UsersReports';
import NotFound from '../containers/NotFound';
import TeamManagement from '../containers/TeamManagement';
import { useAppSelector } from '../redux/hooks';
import { checkForUserRoles } from '../utils';
import Profile from '../containers/Profile';

const AuthNavigation = () => {
  const { auth } = useAppSelector(state => state.auth);
  let displayTeamManagement = false;

  if (auth) {
    displayTeamManagement = checkForUserRoles(auth, ['ADMIN']);
  }

  return (
    <Routes>
      <Route path='/login' element={<Navigate to='/' />}></Route>
      <Route path='/' element={displayTeamManagement ? <TeamManagement /> : <Home />} />
      <Route path='/reports' element={<UsersReports />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default AuthNavigation;
