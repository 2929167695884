import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

const API_URL = "https://reporting-api.creativ-soft.com/api/v1/";

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseErrorCashier = async (
  error: AxiosError
): Promise<AxiosError> => {
  if (error.response) {
    if (
      error.response.status === 401 &&
      (error.response.data as { message: string }).message === "jwt expired"
    ) {
      try {
        const response = await axios.post(`api/v1/secured/token/refresh`, {
          token: "TOKEN",
        });
        const { token } = response.data;

        if (error.config) {
          const updatedConfig: AxiosRequestConfig = {
            ...error.config,
            headers: {
              ...error.config.headers,
              Authorization: `Bearer ${token}`,
            },
          };

          return axios.request(updatedConfig);
        }
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(error);
};

const onRequest = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const bearerToken = localStorage.getItem("bearerToken");

  if (bearerToken) {
    // @ts-ignore
    config.headers.Authorization = `Bearer ${bearerToken}`;
  }
  return config;
};

const setupInterceptors = (axiosInstance: AxiosInstance): AxiosInstance => {
  // @ts-ignore
  axiosInstance.interceptors.request.use(onRequest);
  axiosInstance.interceptors.response.use(onResponse, onResponseErrorCashier);
  return axiosInstance;
};

export const appAxios = setupInterceptors(axios.create({ baseURL: API_URL }));
