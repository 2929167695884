import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getEmployeeReport } from '../../redux/reports/reportsThunk';
import { Card, ListGroup, ListGroupItem, Table } from 'react-bootstrap';

export interface IReportProps {
  employeeId: string;
}

export const Report = ({ employeeId }: IReportProps) => {
  const dispatch = useAppDispatch();
  const { report, date } = useAppSelector(state => state.reports);

  useEffect(() => {
    dispatch(
      getEmployeeReport({
        date: date,
        userId: employeeId,
      })
    );

    // eslint-disable-next-line
  }, []);

  return (
    <div className='report-wrapper'>
      <Card>
        <Card.Header>
          <Card.Title>
            Report
            <div className='total-tracked-hours'>
              <div>
                Total (h) - <b>{report.hoursTracked || '00:00'}</b>
              </div>
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {Object.keys(report).length > 0 ? (
            <>
              <div className='sub-title'>Employee Details</div>
              <ListGroup>
                <ListGroupItem>
                  <b>Email:</b> {report.users.email}
                </ListGroupItem>
              </ListGroup>
              <div className='sub-title mt-3'>Report Details</div>
              <Table striped bordered hover variant='dark'>
                <thead>
                  <tr>
                    <th>Project</th>
                    <th>Ticket</th>
                    <th>Description</th>
                    <th>Time (h)</th>
                  </tr>
                </thead>
                <tbody>
                  {report?.times?.map((time: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{time.projects.name}</td>
                        <td>{time.title}</td>
                        <td>{time.comment || '-'}</td>
                        <td>{time.time}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <div>No details found for this report.</div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Report;
