import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { saveTickets, sendReport, getUserCurrentReports, getProjects } from '../../redux/reports/reportsThunk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../utils';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Modal from 'react-modal';
import Select from 'react-select';
import { toast } from 'react-toastify';

export const ReportForm = () => {
  const dispatch = useAppDispatch();
  const { projects, tickets, isReportSent, date } = useAppSelector<any>(state => state.reports);
  const ticket = {
    projects_id: '',
    slug: '',
    title: '',
    comment: '',
    time: '',
  };
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const [payload, setPayload] = useState<any>([ticket]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [disableFields, setDisableFields] = useState(isReportSent);
  const [projectSelectOptions, setProjectSelectOptions] = useState<any>([]);
  const [totalWorkedTime, setTotalWorkedTime] = useState(0);

  useEffect(() => {
    dispatch(getProjects({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tickets && tickets.length > 0) {
      let totalWorkedTime = 0;
      let timeArray: string[] = [];

      const tempTickets = tickets.map((ticket: any, index: number) => {
        timeArray.push(ticket?.time);

        return {
          id: ticket?.id,
          projects_id: ticket?.projects?.id,
          slug: ticket?.slug,
          title: ticket?.title,
          comment: ticket?.comment,
          time: ticket?.time,
        };
      });

      totalWorkedTime = calculateTotalTime(timeArray);

      setTotalWorkedTime(totalWorkedTime);

      setPayload(() => {
        if (disableFields) {
          return [...tempTickets];
        }

        return [...tempTickets, ticket];
      });
    } else {
      setTotalWorkedTime(0);
      setPayload([ticket]);
    }
    // eslint-disable-next-line
  }, [tickets]);

  useEffect(() => {
    let Today: any = new Date();
    let Yesterday: any = Today - 1000 * 60 * 60 * 24 * 1;
    Yesterday = new Date(Yesterday);

    if ((date !== formatDate(Today) && date !== formatDate(Yesterday) && !isReportSent) || isReportSent) {
      setDisableFields(true);
    } else {
      setDisableFields(false);
    }

    getUserReports(date);
    // eslint-disable-next-line
  }, [date, isReportSent]);

  useEffect(() => {
    if (projects && projects.length > 0) {
      const tempOptions: any = [];

      projects.forEach((project: any) => {
        tempOptions.push({
          value: project.id,
          label: project.name,
        });
      });

      setProjectSelectOptions(tempOptions);
    }
  }, [projects]);

  const calculateHours = (timeString: string): number => {
    const [hoursStr, minutesStr] = timeString.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    const totalMinutes = hours * 60 + minutes;
    const totalHours = totalMinutes / 60;

    return totalHours;
  };

  const calculateTotalTime = (timeArray: string[]): number => {
    let totalHours = 0;

    timeArray.forEach(timeString => {
      totalHours += calculateHours(timeString);
    });

    return totalHours;
  };

  const decimalHoursToTimeString = (decimalHours: number): string => {
    let hours = Math.floor(decimalHours);
    let minutes = Math.round((decimalHours - hours) * 60);

    if (minutes === 60) {
      minutes = 0;
      hours = hours + 1;
    }

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  const onDeleteTicket = (index: number) => {
    if (payload.length > 0) {
      const tempPayload = payload.filter((_: any, ticketIndex: number) => ticketIndex !== index);

      setPayload(() => {
        return [...tempPayload];
      });

      const ticketsToSave = tempPayload.filter((ticket: any) => ticket.id);

      onSaveTickets(ticketsToSave);
    }
  };

  const onchange = (e: any, index: number) => {
    let value, name;

    if (!e.label) {
      value = e.target.value;
      name = e.target.name;
    } else {
      name = 'projects_id';
      value = e.value;
    }

    let ticketSettledFields = 0;

    let tempPayload = payload;

    tempPayload[index][name] = value;

    Object.keys(ticket).forEach(key => {
      if (key === 'time') {
        if (tempPayload[index][key].length === 5) {
          ticketSettledFields++;
        }
      } else {
        if (tempPayload[index][key] !== '' && key !== 'comment') ticketSettledFields++;
      }
    });

    if (ticketSettledFields >= 3) {
      onSaveTickets(tempPayload);
    }

    setPayload(() => {
      return [...tempPayload];
    });
  };

  const onSaveTickets = (times: any) => {
    const ticketsToSave: any[] = [];

    times.forEach((time: any) => {
      if (time.projects_id !== '' && time.title !== '' && time.time.length === 5) {
        ticketsToSave.push(time);
      }
    });

    dispatch(
      saveTickets({
        date: date,
        times: ticketsToSave,
      })
    );
  };

  const onBlur = (e: any, index: number) => {
    const { value } = e.target;
    let tempPayload = payload;

    if (!value.includes(':') && !value.includes('.')) {
      toast.warning('Please enter time in the format HH:MM or H.MM');
    } else if (value.includes(':')) {
      const parts = value.split(':');
      if (parts.length === 2) {
        let hours = parseInt(parts[0]);
        let minutes = parseInt(parts[1]);

        if (!isNaN(hours) && !isNaN(minutes)) {
          if (parts[1].length === 1) {
            minutes *= 10;
          }

          hours = Math.min(Math.max(hours, 0), 999);
          minutes = Math.min(Math.max(minutes, 0), 59);

          const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
          const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
          tempPayload[index]['time'] = `${formattedHours}:${formattedMinutes}`;
        }
      }
    } else if (value.includes('.')) {
      const decimalParts = value.split('.');
      if (decimalParts.length === 2) {
        let hours = parseInt(decimalParts[0]);
        let minutes = parseInt(decimalParts[1]);

        minutes = Math.round((minutes * 60) / 10);

        if (!isNaN(hours) && !isNaN(minutes)) {
          hours = Math.min(Math.max(hours, 0), 999);
          minutes = Math.min(Math.max(minutes, 0), 59);

          const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
          const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
          tempPayload[index]['time'] = `${formattedHours}:${formattedMinutes}`;
        }
      }
    }

    setPayload(() => {
      return [...tempPayload];
    });
    onchange({ target: { value: tempPayload[index]['time'], name: 'time' } }, index);
  };

  const getUserReports = (data?: string) => {
    dispatch(
      getUserCurrentReports({
        date: !data ? formatDate(new Date()) : data,
      })
    ).then((res: any) => {
      if (!res.payload) {
        setPayload([ticket]);
      }
    });
  };

  const onSubmitReport = () => {
    dispatch(sendReport({ date: date })).then(() => {
      closeModal();
      getUserReports();
      setDisableFields(true);
    });
  };

  const renderTooltip = (props: any) => {
    return (
      <Tooltip id='button-tooltip' {...props}>
        Please add time with : or .
      </Tooltip>
    );
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className='report-form-wrapper'>
      <div className='report-form'>
        <div className='report-form-header'>
          <div className='report-form-header-item'>Project</div>
          <div className='report-form-header-item'>Ticket</div>
          <div className='report-form-header-item'>Description</div>
          <div className='report-form-header-item time'>Time (h)</div>
          {!disableFields && <div className='report-form-header-item action'></div>}
          <div className='total-worked-time'>
            Total (h): <b>{decimalHoursToTimeString(totalWorkedTime)}</b>
          </div>
        </div>

        {payload?.map((ticket: any, index: number) => {
          const selectedOption = projectSelectOptions.filter((option: any) => option.value === ticket?.projects_id)[0];
          const hasSelectValue = selectedOption?.label ? true : false;

          return (
            <div className='report-form-field' key={index}>
              <div className='report-form-field-item'>
                {!disableFields ? (
                  <Select
                    className={`${hasSelectValue ? 'selected' : ''} select`}
                    options={projectSelectOptions}
                    name='projects_id'
                    onChange={e => onchange(e, index)}
                    placeholder={selectedOption?.label || 'Select Project'}
                  />
                ) : (
                  <div className='disable'>{selectedOption?.label || '...'}</div>
                )}
              </div>
              <div className='report-form-field-item'>
                {!disableFields ? (
                  <input
                    type='text'
                    placeholder='Ticket Name/Id'
                    name='title'
                    onChange={e => onchange(e, index)}
                    value={ticket.title || ''}
                  />
                ) : (
                  <div className='disable'>{ticket.title || '...'}</div>
                )}
              </div>
              <div className='report-form-field-item'>
                {!disableFields ? (
                  <input
                    type='text'
                    placeholder='Add Description'
                    name='comment'
                    onChange={e => onchange(e, index)}
                    value={ticket.comment || ''}
                  />
                ) : (
                  <div className='disable'>{ticket.comment || '...'}</div>
                )}
              </div>
              <div className='report-form-field-item time'>
                {!disableFields ? (
                  <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                    <input
                      type='text'
                      placeholder='00:00'
                      name='time'
                      onBlur={e => onBlur(e, index)}
                      onChange={e => onchange(e, index)}
                      value={ticket.time || ''}
                    />
                  </OverlayTrigger>
                ) : (
                  <div className='disable'>{ticket.time || '00:00'}</div>
                )}
              </div>
              {!disableFields && (
                <>
                  {index < payload.length - 1 ? (
                    <div className='report-form-field-item action' onClick={() => onDeleteTicket(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  ) : (
                    <div className='report-form-field-item action'></div>
                  )}
                </>
              )}
            </div>
          );
        })}

        {!disableFields && (
          <div className='form-buttons'>
            <button className='send' onClick={() => setIsOpen(true)}>
              Submit report
            </button>
          </div>
        )}

        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
          <div>Are you sure you want to submit Today's report?</div>
          <div className='modal-buttons'>
            <button className='cancel' onClick={() => setIsOpen(false)}>
              Cancel
            </button>
            <button className='submit' onClick={() => onSubmitReport()}>
              Confirm
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ReportForm;
