import { createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from '../axiosInstance';

export const getSubscriptions = createAsyncThunk<any, any, any>('subscriptions/Subscriptions', async (data, _) => {
  try {
    const response = await appAxios.get('/secured/mail-subscriptions');
    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const createSubscription = createAsyncThunk<any, any>('subscriptions/CreateSubscription', async data => {
  try {
    const response = await appAxios.post('/secured/mail-subscriptions', data);
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error;
  }
});

export const deleteSubscription = createAsyncThunk<any, any, any>(
  'subscriptions/DeleteSubscriptions',
  async (data, _) => {
    try {
      const response = await appAxios.delete(`/secured/mail-subscriptions/${data.id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);
