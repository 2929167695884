import { InputGroup, Form, Card, Button, Row, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useEffect, useState } from 'react';
import { getUserProfile, updateUserProfile } from '../../redux/users/usersThunk';
import { formatDate } from '../../utils';
import ProfileCard from '../ProfileCard';
import { toast } from 'react-toastify';
import Spinner from '../Spinner';

const ProfileInfo = () => {
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector(state => state.auth);
  const { profile, loading } = useAppSelector(state => state.users);

  const [userProfile, setUserProfile] = useState({
    ...profile,
  });

  useEffect(() => {
    dispatch(
      getUserProfile({
        id: auth.id,
      })
    );

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUserProfile(() => {
      return { ...profile };
    });
  }, [profile]);

  const onInputChange = (e: any) => {
    const { value, name } = e.target || {};

    setUserProfile((prevData: any) => {
      return { ...prevData, [name]: value };
    });
  };

  const updateProfile = () => {
    const profilePayload = { ...userProfile };
    profilePayload.roles = userProfile.roles[0];
    delete profilePayload.company;
    profilePayload.birthDay = profilePayload.birthday;

    dispatch(
      updateUserProfile({
        id: userProfile.id,
        profile: profilePayload,
      })
    ).then((res: any) => {
      if (res.payload) {
        toast.success('Profile SuccessFully Updated!');
      }
    });
  };

  return (
    <div className='profile-info-wrapper'>
      {userProfile && Object.keys(userProfile).length > 0 && !loading ? (
        <Row>
          <Col sm={4}>
            <ProfileCard />
          </Col>
          <Col sm={8}>
            <Card className='p-5 border-top-0'>
              <h3>Profile</h3>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='email'>Email</InputGroup.Text>
                <Form.Control
                  aria-label='Default'
                  aria-describedby='email'
                  name='email'
                  defaultValue={userProfile.email}
                  onChange={onInputChange}
                />
              </InputGroup>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='firstName'>First Name</InputGroup.Text>
                <Form.Control
                  aria-label='Default'
                  aria-describedby='firstName'
                  name='firstName'
                  defaultValue={userProfile.firstName}
                  onChange={onInputChange}
                />
              </InputGroup>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='lastName'>Last Name</InputGroup.Text>
                <Form.Control
                  aria-label='Default'
                  aria-describedby='lastName'
                  name='lastName'
                  defaultValue={userProfile.lastName}
                  onChange={onInputChange}
                />
              </InputGroup>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='position'>Work Position</InputGroup.Text>
                <Form.Control
                  aria-label='Default'
                  aria-describedby='position'
                  name='position'
                  defaultValue={userProfile.position}
                  onChange={onInputChange}
                />
              </InputGroup>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='birthday'>Birthday</InputGroup.Text>
                <Form.Control
                  aria-label='Default'
                  aria-describedby='birthday'
                  name='birthday'
                  defaultValue={formatDate(new Date(userProfile.birthday))}
                  onChange={onInputChange}
                  type='date'
                />
              </InputGroup>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='phoneNumber'>Phone Number</InputGroup.Text>
                <Form.Control
                  aria-label='Default'
                  aria-describedby='phoneNumber'
                  name='phoneNumber'
                  defaultValue={userProfile.phoneNumber}
                  onChange={onInputChange}
                />
              </InputGroup>
              <div className='d-flex justify-content-end'>
                <Button className='px-4' onClick={updateProfile}>
                  Save
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default ProfileInfo;
