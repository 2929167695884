import { createSlice } from '@reduxjs/toolkit';
import { authentication, fetchToken, getUserProfile, logout, updateToken } from './authThunk';

type AuthState = {
  auth: null | any;
  loading: boolean;
  error: any;
};

const initialState: AuthState = {
  auth: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authentication.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authentication.fulfilled, (state, { payload }) => {
        if (payload) {
          if (payload?.id) {
            localStorage.setItem('userId', payload?.id);
          }

          if (payload?.username) {
            localStorage.setItem('username', payload?.username);
          }

          if (payload?.accessToken) {
            localStorage.setItem('accessToken', payload?.accessToken);
          }
        } else {
          return;
        }

        state.loading = false;
        state.error = null;
      })
      .addCase(authentication.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(fetchToken.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchToken.fulfilled, (state, { payload }) => {
        if (payload) {
          localStorage.setItem('bearerToken', payload?.bearerToken);
          localStorage.setItem('refreshToken', payload?.refreshToken);
        } else {
          return;
        }

        state.loading = false;
        state.error = null;
      })
      .addCase(fetchToken.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(getUserProfile.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.auth = payload;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(logout.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        localStorage.removeItem('userId');
        localStorage.removeItem('username');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('refreshToken');

        state.error = null;
        state.auth = null;
        state.loading = false;
      })
      .addCase(logout.rejected, (state, action) => {
        localStorage.removeItem('userId');
        localStorage.removeItem('username');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('refreshToken');

        state.error = action.payload;
        state.auth = null;
        state.loading = false;
      })

      .addCase(updateToken.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateToken.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.auth = {
          userId: localStorage.getItem('userId'),
          username: localStorage.getItem('username'),
        };
        localStorage.setItem('accessToken', JSON.stringify(payload.token));
      })
      .addCase(updateToken.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { clearError } = authSlice.actions;

export default authSlice.reducer;
