import { Button, Col, InputGroup, Row, Form, Card } from 'react-bootstrap';
import ProfileCard from '../ProfileCard';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useState } from 'react';
import { changePassword } from '../../redux/users/usersThunk';
import { toast } from 'react-toastify';
import Spinner from '../Spinner';

const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector(state => state.auth);
  const { loading } = useAppSelector(state => state.users);
  const [payload, setPayload] = useState({
    id: auth?.id || '',
    password: '',
    confirmPassword: '',
  });
  const [formErrors, setFormErrors] = useState<any>({
    password: false,
    confirmPassword: false,
  });

  const onInputChange = (e: any) => {
    const { name, value } = e.target || {};

    setPayload((prevData: any) => {
      return { ...prevData, [name]: value };
    });
  };

  const updatePassword = () => {
    if (!payload.password) {
      setFormErrors(() => {
        return {
          password: 'Password is required',
          confirmPassword: false,
        };
      });

      return;
    }

    if (!payload.confirmPassword) {
      setFormErrors(() => {
        return {
          password: false,
          confirmPassword: 'Confirm Password is required',
        };
      });

      return;
    }

    if (payload.password && payload.password.length < 6) {
      setFormErrors(() => {
        return {
          password: 'You have to enter at least 6 digit',
          confirmPassword: false,
        };
      });

      return;
    }

    if (payload.confirmPassword && payload.confirmPassword.length < 6) {
      setFormErrors(() => {
        return {
          password: false,
          confirmPassword: 'You have to enter at least 6 digit',
        };
      });

      return;
    }

    if (payload.password && payload.confirmPassword && payload.password !== payload.confirmPassword) {
      setFormErrors(() => {
        return {
          password: 'Passwords do not match',
          confirmPassword: 'Passwords do not match',
        };
      });

      return;
    }

    if (payload.password && payload.confirmPassword && payload.id) {
      dispatch(changePassword(payload)).then(res => {
        if (res.payload) {
          toast.success('Password Successfully updated!');
        }
      });

      setFormErrors(() => {
        return {
          password: false,
          confirmPassword: false,
        };
      });

      setPayload(() => {
        return {
          id: auth?.id || '',
          password: '',
          confirmPassword: '',
        };
      });
    }
  };

  return (
    <div className='change-password-wrapper'>
      <Row>
        <Col sm={4}>
          <ProfileCard />
        </Col>
        <Col sm={8}>
          {!loading ? (
            <Card className='p-5'>
              <h3>Change Password</h3>
              <InputGroup>
                <InputGroup.Text id='password'>Password</InputGroup.Text>
                <Form.Control
                  aria-label='Default'
                  aria-describedby='password'
                  name='password'
                  value={payload.password}
                  onChange={onInputChange}
                  className={`${formErrors.password ? 'border-danger' : ''}`}
                />
              </InputGroup>
              {formErrors.password && (
                <div className='d-block'>
                  <small className='text-danger'>{formErrors.password}</small>
                </div>
              )}
              <InputGroup className='mt-3'>
                <InputGroup.Text id='email'>Confirm password</InputGroup.Text>
                <Form.Control
                  aria-label='Default'
                  aria-describedby='confirmPassword'
                  name='confirmPassword'
                  value={payload.confirmPassword}
                  onChange={onInputChange}
                  className={`${formErrors.confirmPassword ? 'border-danger' : ''}`}
                />
              </InputGroup>
              {formErrors.confirmPassword && (
                <div className='d-block'>
                  <small className='text-danger'>{formErrors.confirmPassword}</small>
                </div>
              )}
              <div className='d-flex justify-content-end mt-3'>
                <Button className='px-4' onClick={updatePassword}>
                  Save
                </Button>
              </div>
            </Card>
          ) : (
            <div>
              <Spinner />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ChangePassword;
