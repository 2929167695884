import { createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from '../axiosInstance';

export const getUserCurrentReports = createAsyncThunk<any, any, any>('reports/UserCurrentReports', async (data, _) => {
  try {
    const response = await appAxios.get('/secured/activities/' + data.date);

    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const getProjects = createAsyncThunk<any, any, any>('reports/getProjects', async (data, _) => {
  try {
    const response = await appAxios.get('/secured/projects', {
      params: {
        ...data,
        limit: -1,
      },
    });

    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const saveTickets = createAsyncThunk<any, any, any>('tickets/save', async (data, _) => {
  try {
    const response = await appAxios.post('/secured/activities/' + data?.date, {
      times: [...data?.times],
    });

    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const sendReport = createAsyncThunk<any, any, any>('report/send', async (data, _) => {
  try {
    const response = await appAxios.post('/secured/activities/' + data?.date + '/send', {
      userId: data?.userId || '',
    });

    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const getUsersReports = createAsyncThunk<any, any, any>('users/reports/get', async (data, _) => {
  try {
    const response = await appAxios.get('/secured/activities', {
      params: {
        fromDate: data.date,
        toDate: data.date,
      },
    });

    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const updateDate = createAsyncThunk<any, any>('update/date', async date => {
  try {
    return date;
  } catch (error: any) {
    console.log(error);
  }
});

export const getEmployeeReport = createAsyncThunk<any, any>('reports/get/employee/report', async (data, _) => {
  try {
    const response = await appAxios.get('/secured/activities/' + data.date, {
      params: {
        userId: data.userId,
      },
    });

    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});
