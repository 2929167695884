import { createSlice } from '@reduxjs/toolkit';
import {
  attachEmployeesToTechLead,
  changePassword,
  createUserSubscriptions,
  deleteUserSubscriptions,
  getSelectedTechLead,
  getUserProfile,
  getUserSubscriptions,
  getUsers,
  updateUserProfile,
} from './usersThunk';

type UsersState = {
  users: null | any;
  loading: boolean;
  error: any;
  techLeads: null | any;
  employees: null | any;
  selectedTechLead: null | any;
  techLeadSubscriptions: null | any;
  profile: null | any;
};

const initialState: UsersState = {
  users: [],
  loading: true,
  error: null,
  techLeads: [],
  employees: [],
  selectedTechLead: null,
  techLeadSubscriptions: [],
  profile: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(getUsers.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        const techLeads = payload?.filter((user: any) => user.roles.includes('TECH_LEAD'));
        const employees = payload?.filter((user: any) => user.roles.includes('EMPLOYEE'));

        state.loading = false;
        state.error = null;
        state.techLeads = techLeads;
        state.employees = employees;
        state.users = payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(getSelectedTechLead.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSelectedTechLead.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.selectedTechLead = payload;
      })
      .addCase(getSelectedTechLead.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.selectedTechLead = null;
      })

      .addCase(attachEmployeesToTechLead.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(attachEmployeesToTechLead.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(attachEmployeesToTechLead.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(getUserSubscriptions.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserSubscriptions.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.techLeadSubscriptions = payload;
      })
      .addCase(getUserSubscriptions.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(createUserSubscriptions.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUserSubscriptions.fulfilled, (state, { payload }) => {
        const subscriptions = [...state.techLeadSubscriptions];

        subscriptions.push({
          usersId: payload,
        });

        state.error = null;
        state.loading = false;
        state.techLeadSubscriptions = subscriptions;
      })
      .addCase(createUserSubscriptions.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(deleteUserSubscriptions.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUserSubscriptions.fulfilled, (state, { payload }) => {
        const subscriptions =
          state.techLeadSubscriptions.filter((subscription: any) => subscription.usersId !== payload) || [];

        state.error = null;
        state.loading = false;
        state.techLeadSubscriptions = [...subscriptions];
      })
      .addCase(deleteUserSubscriptions.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(getUserProfile.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.profile = payload;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.profile = null;
      })

      .addCase(updateUserProfile.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
        state.profile = payload;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(changePassword.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { clearError } = usersSlice.actions;

export default usersSlice.reducer;
