import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChartPie, faClock } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { checkForUserRoles } from '../../utils';

export const Sidebar = () => {
  const { auth } = useAppSelector(state => state.auth);
  const displayReports = checkForUserRoles(auth, ['TECH_LEAD', 'ADMIN']);

  return (
    <div className='sidebar'>
      <ul className='sidebar-list'>
        <li className='sidebar-list-item'>
          <p className='title'>
            <FontAwesomeIcon icon={faClock} /> Ticketing
          </p>
        </li>
        <li className='sidebar-list-item'>
          <NavLink to='/'>
            <FontAwesomeIcon icon={faHome} /> Dashboard
          </NavLink>
        </li>
        {displayReports && (
          <li className='sidebar-list-item'>
            <NavLink to='/reports'>
              {' '}
              <FontAwesomeIcon icon={faChartPie} /> Reports
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
