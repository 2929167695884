import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  const { auth } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (!auth) {
      navigate('/login');
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className='not-found-wrapper'>
      <h1>404</h1>
      <h2>Page not found.</h2>
      <NavLink to={'/'}>Go to home page</NavLink>
    </div>
  );
};

export default NotFound;
