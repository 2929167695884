import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { attachEmployeesToTechLead, getSelectedTechLead, getUsers } from '../../redux/users/usersThunk';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import Spinner from '../Spinner';

export const TeamManagementContent = () => {
  const dispatch = useAppDispatch();
  const { techLeads, employees, selectedTechLead, loading } = useAppSelector(state => state.users);
  const [attachedEmpolyees, setAttachedEmployees] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getUsers());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let attachedEmployeedIds = [];

    if (selectedTechLead && selectedTechLead.attachedEmpoyees) {
      attachedEmployeedIds = selectedTechLead.attachedEmpoyees.map((employee: any) => {
        return employee.id;
      });
    }
    setAttachedEmployees(attachedEmployeedIds);
  }, [selectedTechLead]);

  useEffect(() => {
    if (loading === true) {
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    } else {
      setIsLoading(true);
    }
  }, [loading]);

  const onSelectChange = (e: any) => {
    const { value } = e.target;

    dispatch(
      getSelectedTechLead({
        id: value,
      })
    );
  };

  const onTeamChanged = (e: any, id: string) => {
    const { checked } = e.target;

    if (checked) {
      setAttachedEmployees((prevAttachedEmployees: any) => {
        return [...prevAttachedEmployees, id];
      });
    } else {
      const tempAttachedEmployees = attachedEmpolyees.filter((employeeId: any) => employeeId !== id);

      setAttachedEmployees(() => {
        return [...tempAttachedEmployees];
      });
    }
  };

  const onSaveTeam = () => {
    dispatch(
      attachEmployeesToTechLead({
        id: selectedTechLead.id,
        employeesIds: attachedEmpolyees,
      })
    );
  };

  return (
    <div className='team-management-wrapper'>
      <div className='tech-leads mt-5'>
        <h5>Tech Leads</h5>
        <select defaultValue={selectedTechLead?.id} onChange={onSelectChange}>
          <option>Select Tech Lead</option>
          {techLeads?.map((techLead: any) => {
            return (
              <option key={techLead.id} value={techLead.id}>
                {techLead.firstName + ' ' + techLead.lastName}
              </option>
            );
          })}
        </select>
      </div>
      {selectedTechLead && (
        <>
          <div className='employees mt-5'>
            <h5>All Employees</h5>
            <ListGroup>
              <ListGroupItem>
                <div className='id'>#</div>
                <div className='name'>Name</div>
                <div className='email'>Email</div>
                <div className='position'>Position</div>
                <div className='actions'>Add Employee</div>
              </ListGroupItem>
              {employees?.map((employee: any) => {
                return (
                  <ListGroupItem key={employee.id}>
                    <div className='id'>{employee.id}</div>
                    <div className='name'>{employee.firstName + ' ' + employee.lastName}</div>
                    <div className='email'>{employee.email}</div>
                    <div className='position'>{employee.position}</div>
                    <div className='actions'>
                      <input
                        type='checkbox'
                        checked={attachedEmpolyees.includes(employee.id) ? true : false}
                        onChange={e => {
                          onTeamChanged(e, employee.id);
                        }}
                      />
                    </div>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
            {isLoading && (
              <div className='loader'>
                <Spinner />
              </div>
            )}
          </div>
          <div className='save'>
            <button disabled={isLoading || !selectedTechLead} onClick={onSaveTeam}>
              Save Team
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TeamManagementContent;
