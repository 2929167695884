import ReportForm from '../../components/ReportForm';
import { useAppSelector } from '../../redux/hooks';
import { checkForUserRoles } from '../../utils';

const Home = () => {
  const { auth } = useAppSelector<any>(state => state.auth);

  return <>{!checkForUserRoles(auth, ['SUPER_ADMIN']) && <ReportForm />}</>;
};

export default Home;
