import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../containers/Login';
import NotFound from '../containers/NotFound';

const UnauthNavigation = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/login' />} />
      <Route path='/login' element={<Login />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default UnauthNavigation;
