import { createSlice } from '@reduxjs/toolkit';
import {
  getEmployeeReport,
  getProjects,
  getUserCurrentReports,
  getUsersReports,
  saveTickets,
  sendReport,
  updateDate,
} from './reportsThunk';
import { formatDate } from '../../utils';

type AuthState = {
  report: null | any;
  loading: boolean;
  error: any;
  isReportSent: boolean;
  projects: any[];
  tickets: any[];
  usersReports: null | any;
  date: any;
};

const initialState: AuthState = {
  report: {},
  projects: [],
  loading: true,
  error: null,
  isReportSent: false,
  tickets: [],
  usersReports: [],
  date: formatDate(new Date()),
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUserCurrentReports.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserCurrentReports.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.tickets = payload?.times || [];
        state.report = payload || {};

        if (payload) {
          state.isReportSent = payload?.status === 'IN_PROGRESS' ? false : true;
        }
      })
      .addCase(getUserCurrentReports.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.report = null;
      })

      .addCase(getProjects.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.projects = payload;
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(saveTickets.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveTickets.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.tickets = payload?.times || [];
      })
      .addCase(saveTickets.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(getUsersReports.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsersReports.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.usersReports = payload || [];
      })
      .addCase(getUsersReports.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.usersReports = [];
      })

      .addCase(updateDate.fulfilled, (state, { payload }) => {
        state.date = payload;
      })

      .addCase(getEmployeeReport.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEmployeeReport.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.report = payload || {};
      })
      .addCase(getEmployeeReport.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.report = {};
      })

      .addCase(sendReport.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendReport.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.isReportSent = true;
      })
      .addCase(sendReport.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { clearError } = reportsSlice.actions;

export default reportsSlice.reducer;
