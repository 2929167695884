import { createAsyncThunk } from '@reduxjs/toolkit';
import { appAxios } from '../axiosInstance';

export const getUsers = createAsyncThunk<any>('users/UsersInfo', async () => {
  try {
    const response = await appAxios.get('/secured/users');
    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const getSelectedTechLead = createAsyncThunk<any, any, any>('users/get/selected/techLead', async (data, _) => {
  try {
    const response = await appAxios.get('/secured/users/' + data.id);
    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const attachEmployeesToTechLead = createAsyncThunk<any, any>('users/attach/employees', async (data, _) => {
  try {
    const response = await appAxios.post('/secured/users/' + data.id + '/attach-employees', {
      employeesIds: data.employeesIds,
      techLeadId: data.id,
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const getUserSubscriptions = createAsyncThunk<any>('users/get/subscriptions', async () => {
  try {
    const response = await appAxios.get('/secured/mail-subscriptions');

    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const createUserSubscriptions = createAsyncThunk<any, any>('users/create/subscriptions', async (data, _) => {
  try {
    await appAxios.post('/secured/mail-subscriptions', {
      usersId: data.usersId,
    });

    return data.usersId;
  } catch (error: any) {
    console.log(error);
  }
});

export const deleteUserSubscriptions = createAsyncThunk<any, any>('users/delete/subscription', async (data, _) => {
  try {
    await appAxios.delete(`/secured/mail-subscriptions/${data.id}`);

    return data.employeeId;
  } catch (error: any) {
    console.log(error);
  }
});

export const getUserProfile = createAsyncThunk<any, any>('users/get/profile', async (data, _) => {
  try {
    const response = await appAxios.get('/secured/users/' + data.id);

    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const updateUserProfile = createAsyncThunk<any, any>('users/update/profile', async (data, _) => {
  try {
    const response = await appAxios.put('/secured/users/' + data.id, {
      ...data.profile,
    });

    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const changePassword = createAsyncThunk<any, any>('users/change/password', async (data, _) => {
  try {
    const response = await appAxios.post('/secured/users/password/set', {
      ...data,
    });

    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});
